import React from "react";

function Instagram({ fill }) {
  return (
    <svg
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38.25 10.125C38.25 11.989 36.739 13.5 34.875 13.5C33.011 13.5 31.5 11.989 31.5 10.125C31.5 8.26104 33.011 6.75 34.875 6.75C36.739 6.75 38.25 8.26104 38.25 10.125Z"
        fill={fill}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22.5 33.75C28.7132 33.75 33.75 28.7132 33.75 22.5C33.75 16.2868 28.7132 11.25 22.5 11.25C16.2868 11.25 11.25 16.2868 11.25 22.5C11.25 28.7132 16.2868 33.75 22.5 33.75ZM22.5 29.25C26.2279 29.25 29.25 26.2279 29.25 22.5C29.25 18.7721 26.2279 15.75 22.5 15.75C18.7721 15.75 15.75 18.7721 15.75 22.5C15.75 26.2279 18.7721 29.25 22.5 29.25Z"
        fill={fill}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 21.6C0 14.0393 0 10.2589 1.47141 7.37113C2.7657 4.83094 4.83094 2.7657 7.37113 1.47141C10.2589 0 14.0393 0 21.6 0H23.4C30.9607 0 34.7411 0 37.6289 1.47141C40.1691 2.7657 42.2343 4.83094 43.5286 7.37113C45 10.2589 45 14.0393 45 21.6V23.4C45 30.9607 45 34.7411 43.5286 37.6289C42.2343 40.1691 40.1691 42.2343 37.6289 43.5286C34.7411 45 30.9607 45 23.4 45H21.6C14.0393 45 10.2589 45 7.37113 43.5286C4.83094 42.2343 2.7657 40.1691 1.47141 37.6289C0 34.7411 0 30.9607 0 23.4V21.6ZM21.6 4.5H23.4C27.2546 4.5 29.8749 4.5035 31.9003 4.66898C33.8732 4.83017 34.8821 5.12234 35.5859 5.48094C37.2794 6.3438 38.6562 7.72063 39.5191 9.41409C39.8777 10.1179 40.1698 11.1268 40.331 13.0997C40.4965 15.1251 40.5 17.7454 40.5 21.6V23.4C40.5 27.2546 40.4965 29.8749 40.331 31.9003C40.1698 33.8732 39.8777 34.8821 39.5191 35.5859C38.6562 37.2794 37.2794 38.6562 35.5859 39.5191C34.8821 39.8777 33.8732 40.1698 31.9003 40.331C29.8749 40.4965 27.2546 40.5 23.4 40.5H21.6C17.7454 40.5 15.1251 40.4965 13.0997 40.331C11.1268 40.1698 10.1179 39.8777 9.41409 39.5191C7.72063 38.6562 6.3438 37.2794 5.48094 35.5859C5.12234 34.8821 4.83017 33.8732 4.66898 31.9003C4.5035 29.8749 4.5 27.2546 4.5 23.4V21.6C4.5 17.7454 4.5035 15.1251 4.66898 13.0997C4.83017 11.1268 5.12234 10.1179 5.48094 9.41409C6.3438 7.72063 7.72063 6.3438 9.41409 5.48094C10.1179 5.12234 11.1268 4.83017 13.0997 4.66898C15.1251 4.5035 17.7454 4.5 21.6 4.5Z"
        fill={fill}
      />
    </svg>
  );
}

Instagram.defaultProps = {
  fill: "#FFF8E8",
};
export default Instagram;
