function Linkedin({ fill }) {
  return (
    <svg
      width="45"
      height="42"
      viewBox="0 0 45 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8557 5.07645C10.8557 7.88009 8.42554 10.1529 5.42783 10.1529C2.43012 10.1529 0 7.88009 0 5.07645C0 2.2728 2.43012 0 5.42783 0C8.42554 0 10.8557 2.2728 10.8557 5.07645Z"
        fill={fill}
      />
      <path d="M0.742268 13.8843H10.0206V42H0.742268V13.8843Z" fill={fill} />
      <path
        d="M24.9588 13.8843H15.6804V42H24.9588C24.9588 42 24.9588 33.1488 24.9588 27.6146C24.9588 24.2929 26.093 20.9566 30.6186 20.9566C35.733 20.9566 35.7023 25.3036 35.6784 28.6713C35.6472 33.0733 35.7216 37.5656 35.7216 42H45V27.1612C44.9214 17.6861 42.4525 13.3202 34.3299 13.3202C29.5062 13.3202 26.5161 15.5102 24.9588 17.4915V13.8843Z"
        fill={fill}
      />
    </svg>
  );
}

Linkedin.defaultProps = {
  fill: "#FFF8E8",
};

export default Linkedin;
